// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'karla';
  src: url('/fonts/karla/karla-bold-webfont.woff2') format('woff2'),
  url('/fonts/karla/karla-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'karla';
  src: url('/fonts/karla/karla-regular-webfont.woff2') format('woff2'),
  url('/fonts/karla/karla-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}