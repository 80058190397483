.single {
  .hero-single {
    background-color: $white;
    margin-top: 50px;
    padding-top: 15px;
    padding-bottom: 15px;

    @include respond-to("small") {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .hero-single-content {
      height: 200px;

      @include respond-to("small") {
        height: 366px;
      }
    }

    .hero-single-image {
      height: 366px;
    }

    .actions-single {
      h1.product-name {
        margin-top: 0;
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 40px;
      }
      h3 {
        font-size: 23px;
        margin-top: 6px;
        font-weight: 700;
      }

      .buttons {
        max-width: 320px;

        a {
          margin-right: 5px;
        }

        button {
          width: 150px;
          font-weight: 700;
        }
      }
    }
  }

  .description-single {
    padding-bottom: 100px;
    position: relative;
    height: calc(100% - 25px);

    @include respond-to("small") {
      padding-bottom: 70px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 6px;
      font-weight: 700;
      font-size: 40px;
    }
    h4 {
      font-size: 23px;
      margin-top: 6px;
      margin-bottom: 12px;
      font-weight: 700;
    }
    p.description-text {
      display: block;
      margin-bottom: 20px;
    }

    .more-info {
      position: absolute;
      width: 90%;
      bottom: 13px;

      &:before {
        content: "";
        display: block;
        width: 100%;
        margin: auto;
        height: 1px;
        background-color: #ccc;
      }

      p {
        margin: 20px 0 0 0;
      }

      .share {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 10px;

        a {
          margin-right: 7px;
          margin-left: 7px;
        }
      }

      .docs {
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 7px;
          margin-left: 7px;

          span {
            margin-left: 7px;
          }
        }
      }
    }
  }

  .features-single {
    span {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 40px;
      }
    }
  }

  .stats-single {
    min-height: calc(100% - 147px);

    @include respond-to("small") {
      min-height: calc(100% - 171px);
    }

    @include respond-to("medium") {
      min-height: calc(100% - 147px);
    }

    .stats-single-content {
      max-width: 200px;
      margin: auto;

      .stat-single {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      span.icon {
        width: 30px;
        text-align: center;
      }
    }
  }
}
