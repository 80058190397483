/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 100% / 1.4 $text-font-stack;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $title-font-stack;
}
