.thankyou {
  width: 600px;

  .thankyou-header {
    background-color: #0F015E;
    background-image: -webkit-radial-gradient(farthest-corner at 50% 100%, rgba(123, 22, 255, 0.75), #0F015E);
    background-image: -moz-radial-gradient(farthest-corner at 50% 100%, rgba(123, 22, 255, 0.75), #0F015E);
    background-image: radial-gradient(farthest-corner at 50% 100%, rgba(123, 22, 255, 0.75), #0F015E);
    color: #fff;
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    h1 {
      text-align: center;
      font-size: 40px;
    }

  }

  .thankyou-content, .enter-email-content {
      padding-top: 110px;

    h2 {
      margin: 0 0 16px 0;
      font-size: 20px;
      font-weight: 200;
    }

    input {
      width: 300px;
    }


    button {
      line-height: 24px;
      font-size: 18px;

      .icon {
        font-size: 24px;
      }
    }

    p {
      margin-top: 30px;
      font-size: 14px;
      font-weight: 200;
    }
  }

  .thankyou-footer {
    padding-top: 10px;
    p {
      margin: 0 0 5px 0;
      font-size: 13px;
    }

    h5 {
      font-size: 17px;
      font-weight: 400;
      margin:0;
    }
  }

  .enter-email-content {
    h2 {
        margin: 0 0 4px;
    }
  }

}
