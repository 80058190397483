.header {
  background-color: $white;
  position: relative;
  z-index: 1;

  .flex-wrapper {
    display: block;

    @include respond-to('small') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .navbar-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;

    @include respond-to('small') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .navbar-inner {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .logo {
    h1 {
      color: #fff;
      margin:0;
    }

    img {
      max-width: 150px;
    }
  }

  .navbar-menu {
    list-style-type: none;
    padding: 0;
    margin:0;
    height: 0;
    overflow: hidden;
    background-color: $white;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: height, padding-top;

    &.open {
      height: 120px;
      padding-top: 14px;
    }

    @include respond-to('small') {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li {
      margin-left: 10px;
      padding-top: 7px;

      @include respond-to('small'){
        margin-left: 40px;
        padding-top: 0;
      }

      a {
        font-family: $title-font-stack;
        color: $mid-grey;
        -webkit-transition: color 0.25s linear;
        -moz-transition: color 0.25s linear;
        -ms-transition: color 0.25s linear;
        -o-transition: color 0.25s linear;
        transition: color 0.25s linear;
        @include on-event(){
          text-decoration: none;
          color: $brand-color;
        }
      }
    }
  }

  a.contact-btn {
    display: none;
    color: $brand-color;
    border-color: $brand-color;

    @include respond-to('small') {
      display: block;
    }
  }


  .hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    display: block;

    @include respond-to('small') {
      display: none;
    }
  }

  .hamburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
  }

  .hamburger .hamburger-inner, .hamburger .hamburger-inner:after, .hamburger .hamburger-inner:before {
    background-color: $text-color;
    position: absolute;
    width: 30px;
    height: 4px;
    transition-property: transform;
    border-radius: 10px;

  }

  .hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: .22s;
  }

  .hamburger .hamburger-inner:before {
    top: -8px;
    transition: top .1s ease-in .25s,opacity .1s ease-in;
  }

  .hamburger .hamburger-inner:after {
    bottom: -8px;
    transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
  }

  .hamburger-inner:after, .hamburger-inner:before {
    display: block;
    content: "";
  }

  .hamburger.is-active .hamburger-inner {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: rotate(225deg);
  }

  .hamburger.is-active .hamburger-inner:before {
    top: 0;
    transition: top .1s ease-out,opacity .1s ease-out .12s;
    opacity: 0;
  }

  .hamburger.is-active .hamburger-inner:after {
    bottom: 0;
    transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    transform: rotate(-90deg);
  }


}

.hero {

  .hero-content {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include respond-to('small') {
      height: 300px;
    }
  }

  h1 {
    font-size: 30px;
    max-width: 400px;
    font-weight: 700;
    line-height: 60px;

    @include respond-to('small') {
      font-size: 50px;
      max-width: 700px;
    }
  }
}