.footer {
  background-color: $white;
  margin-top: 50px;


  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding-top: 15px;


    a {
     color: $brand-color;
    }
  }
}