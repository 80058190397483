.preview {
  height: 100vh;

  .preview-header {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;

    .preview-header-content {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .media-picker {

    button {
      background: transparent;
      border: none;
      cursor: pointer;

      &.active {
        color: $brand-color;
      }

      @include on-event(){
        outline: none;
      }
    }
  }

  iframe {
    margin: 60px auto 0;
    display: block;
    height: 100%;
    border: 0;
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -ms-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
}