.btn {
  font-family: $text-font-stack;
  border-radius: 12px;
  padding: 9px 18px;
  border: 1px solid $text-color;
  color: $text-color;
  border-radius: 30px;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: box-shadow 0.2s linear, border-radius 0.2s linear, color 0.2s linear;
  -moz-transition:  box-shadow 0.2s linear, border-radius 0.2s linear, color 0.2s linear;
  -ms-transition:  box-shadow 0.2s linear, border-radius 0.2s linear, color 0.2s linear;
  -o-transition:  box-shadow 0.2s linear, border-radius 0.2s linear, color 0.2s linear;
  transition:  box-shadow 0.2s linear, border-radius 0.2s linear, color 0.2s linear;

  @include on-event() {
    outline: none;
  }
}


.btn-full {
  background-color: $brand-color;
  border: 2px solid $brand-color;
  color: $white;

  @include on-event() {
    outline: none;
    -webkit-box-shadow: -1px 9px 46px -17px rgba(43,43,43,1);
    -moz-box-shadow: -1px 9px 46px -17px rgba(43,43,43,1);
    box-shadow: -1px 9px 46px -17px rgba(43,43,43,1);
  }
}

.btn-black {
  color: $text-color;
  border-color: $text-color;
}