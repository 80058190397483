.products-div {
  padding-bottom: 50px;
  .product {
    position: relative;
    margin: 0 10px 50px;
    border-radius: 2px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;

    .product-image-wrapper {
      position: relative;
      box-shadow: 0 6px 15px rgba(36,37,38,0.1);
      border: 6px solid $white;
      border-radius: 2px;
    }

    img {
      width: 100%;
      border-radius: 2px;
    }


    &:hover {
      .actions {
        opacity: 1;
      }
    }


    .actions {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-out;
      -moz-transition: opacity 0.3s ease-out;
      -ms-transition: opacity 0.3s ease-out;
      -o-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;


      a {
        color: #fff;
        width: 50px;
        height: 50px;
        background-color: #000;
        margin-left: 3px;
        margin-right: 3px;
        text-align: center;
        line-height: 60px;
      }
    }

    .card-info {
      padding: 10px 0;
      text-align: left;

      a {
        color: $text-color;
        @include on-event() {}
        text-decoration: none;
      }

      h3{
        margin:0;
      }

      p {
        margin:0;
        color: $mid-grey;
      }

      .price {
        float: right;

        &.free {
          color: $green;
        }
      }


    }
  }
}