.contact {
  padding-top: 50px;
  min-height: calc(100vh - 220px);

  h1 {
    margin-bottom: 50px;
  }

  form {
    @extend .shadow;
    background-color: $white;
    padding: 15px 30px;
    border-radius: 5px;
  }
}