form {

  .form-group {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  label {
    display: inline-block;
    margin-bottom: .5rem;
  }

  input, textarea {
    font-family: $text-font-stack;
    padding: 8px 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 100%;

    @include on-event() {
      outline: 0;
      box-shadow: 0 0 4px $brand-color;
    }

    &.required {
      box-shadow: 0 0 4px #ff1313;
      border: 1px solid #ff1313;
    }
  }



}