.spinner-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.6);
  z-index: 5;
}

.spinner {
  @extend .shadow-5;
  position: fixed;
  width: 100px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}