// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'Karla', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Title font family
/// @type List
$title-font-stack: 'Karla', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;


$text-color: rgb(34, 34, 34) !default;
$background-color: rgb(242, 245, 249) !default;
$white: rgb(255, 255, 255) !default;
$black: rgb(0, 0, 0) !default;
$brand-color: #1946FF !default;
$light-grey: rgb(237, 237, 237) !default;
$mid-grey: rgb(153, 153, 153) !default;
$dark-grey: rgb(68, 68, 68) !default;
$light-blue: #73CDE2;
$dark-blue: #2D3255;
$baige: #DFE4CD;
$green: #13c700;



/// Container's maximum width
/// @type Length
$max-width: 1170px !default;
$mid-max-width: 970px !default;
$min-max-width: 750px !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
        'x-small': (min-width: 480px),
        'small-minus': (min-width: 480px),
        'small': (min-width: 768px),
        'small-plus': (min-width: 768px),
        'medium': (min-width: 992px),
        'large': (min-width: 1200px),
        'x-large': (min-width: 1400px),
        'xx-large': (min-width: 1600px),
) !default;



/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
